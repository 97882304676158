import React from "react"
import { Link } from "gatsby"
import "./NavBar.css"

function NavBar(props) {
  return (
    <nav className="NavBar">
      <Link to="/" className="NavBar__title">
        Calisthenics Skills Shop
      </Link>
      <ul className="NavBar__list">
        <li className="NavBar__item">
          <a href="https://calisthenicsskills.com">App</a>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
