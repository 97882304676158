/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import NavBar from "./NavBar"
import Header from "./Header"
import Footer from "./Footer"
import "./Layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div
        className="Layout"
        style={{
          margin: `0 auto`,
          maxWidth: "900px",
          padding: `0 8px`,
        }}
      >
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
