import React from "react"
import "./Header.css"

function Header() {
  return (
    <header className="Header">
      <h1>Calisthenics Skills Shop</h1>
    </header>
  )
}

export default Header
