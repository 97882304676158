import React from "react"
import "./Footer.css"

function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer__heading">
        <h3 className="Footer__title">Calisthenics Skills</h3>
      </div>
      <ul className="Footer__list">
        <li>
          <a href="https://calisthenicsskills.com/skill-tree/">Skill Tree</a>
        </li>
        <li>
          <a href="https://calisthenicsskills.com/workouts/">Workouts</a>
        </li>
        <li>
          <a href="https://calisthenicsskills.com/exercises/">Exercise List</a>
        </li>
        <li>
          <a href="https://calisthenicsskills.com/equipment/">Equipment</a>
        </li>
      </ul>
      <ul className="Footer__list">
        <li>
          <a href="https://calisthenicsskills.com/about/">About Us</a>
        </li>
      </ul>
      <ul className="Footer__list">
        <li>
          <a href="https://calisthenicsskills.com/contact-us/">Contact Us</a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/caliskills.app/"
          >
            Instagram
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
